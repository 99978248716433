import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import Autocomplete from 'react-autocomplete';
import { toast } from 'react-toastify';
import { Form, ButtonStyled } from './styles';
import { API } from '../../../config/api';
import { BottomModal } from 'react-spring-modal';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Async from 'react-select/async';
import debounce from 'debounce-promise';


import Select from 'react-select';

import 'react-spring-modal/dist/index.css';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '80%',
    maxHeight: '80%',
    overflow: 'scroll',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  button: {
    border: 0,
    borderRadius: '20px',
    backgroundColor: '#00c156',
    color: '#fff',
    padding: '5px',
    cursor: 'pointer',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '15px',
  },
}));

export default function ModalCreate() {
  // Token
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const perfil = sessionStorage.getItem('perfil');

  const [autoTransp, setautoTransp] = useState([]);
  const [autoTranspRedespacho, setautoTranspRedespacho] = useState('');
  const [nomeTransp, setNomeTransp] = useState('');
  const [nomeTranspRedespacho, setNomeTranspRedespacho] = useState('');
  const [idTransp, setIdTransp] = useState('');
  const [idTranspRedespacho, setIdTranspRedespacho] = useState('');
  const [files_saved, setFiles_saved] = useState('');
  const [przPagamento, setprzPagamento] = useState([]);
  const [taxProfile, setTaxProfile] = useState('');
  const [cityDefault, setcityDefault] = useState('');
  // Modal
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [required, setRequired] = React.useState(false);

  // AutoComplete
  const [auto, setAuto] = useState();
  let value = '';
  const [valueAutoId, setValueAutoId] = useState(value);
  const [valueAutoNome, setValueAutoNome] = useState(value);

  const [logradouroApi, setLogradouro] = useState('');
  const [bairroApi, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [numeroApi, setNumero] = useState('');
  const [telefoneApi, setTelefone] = useState('');
  const [emailApi, setEmail] = useState('');
  const [nomeApi, setNome] = useState('');
  const [nomeFantasiaApi, setNomeFantasia] = useState('');

  const [estado, setEstado] = useState('');
  const [pais, setPais] = useState('');
  const [cep, setCep] = useState('');

  const [complementoApi, setComplemento] = useState('');
  const [taxchoose, setTaxChoose] = useState('');
  const [taxchoosecomplete, setTaxChooseComplete] = useState('');

  const [taxdefault, setTaxdefault] = useState('');

  const [fields, setfields] = useState({
    ativa: true,
    bloqueada: false,
    tipo: 'JURIDICA',
    nome: null,
    apelido: null,
    cnpj: null,
    inscricaoEstadual: null,
    cpf: null,
    rg: null,
    enderecoLogradouro: null,
    enderecoNumero: null,
    enderecoComplemento: null,
    enderecoBairro: null,
    enderecoCidade: null,
    enderecoEstado: null,
    enderecoPais: null,
    enderecoCep: null,
    observacoes: null,
    vendedorPadrao: {
      id: null,
      nome: null,
    },
  });




  const handleOpen = () => {
    setOpen(true);
    getData();

  };
  const handleClose = () => {
    setOpen(false);
  };

  // react-hooks-form
  const { register, handleSubmit } = useForm();

  // Tipo Required
  const [tipoRequired, setTipoRequired] = useState(true);


  const loadcity = async (inputValue, callback, busca) => {
    try {
      const response = await axios.get(`${API.cidadeErp}?${busca}=${inputValue}`, {
        headers: {
          'x-access-token': token,
        },
      });
      const data = response.data.data
        .map(item => {
          return { value: item.id, label: item.name + '/' + item.state.name + ' - ' + item.state.country.name, state: item.state.id, state_name: item.state.code, country: item.state.country.id };
        });
      return data;
    } catch (err) {
      //              toast.error("Não encontrado");
    }
  };
  const loadOptionscity = (inputValue, callback) =>
    loadcity(inputValue, callback, 'parametro');

  const debouncedLoadOptioncity = debounce(loadOptionscity, 1000, {
    leading: true,
  });




  const getData = async () => {


    async function handleTaxProfile() {
      try {
        const response = await axios.get(`${API.clientesPerfilFiscal}`, {
          headers: {
            'x-access-token': token,
          },
        });


        const list = response.data.data.filter(item => {
          return /(Consumidor|Regime Normal|Simples Nacional)/.test(item.description);
        }).map(item => {
          return { value: item.id, label: item.description };
        });
        setTaxdefault(list)
        setTaxProfile(list);
      } catch (err) {
        console.log(err)
        toast.error('Erro ao obter perfil fiscal');
      }
    }
    async function handleReq() {
      try {
        const response = await axios.get(`${API.vendedores}?email=${email}`, {
          headers: {
            'x-access-token': token,
          },
        });
        if (perfil === 'vendedor' && response.data.data[0]) {
          if (response.data.data[0].id) {
            setValueAutoId(response.data.data[0].id);
            setValueAutoNome(response.data.data[0].name);


          }
        }
        const list = response.data.data.map(item => {
          return { value: item.id, label: item.name };
        });

        setAuto(list);
      } catch (err) {
        toast.error('Erro ao listar vendedores');
      }
    }

    handleReq();
    handleTaxProfile();
  }


  const handleRequiretipo = e => {
    if (e === "FISICA") {
      setTipoRequired(false);
      setRequired(false);

      setfields({
        ...fields,
        'tipo': 'FISICA'
      });

    } else {
      setTipoRequired(true);
      setRequired("required");
      setfields({
        ...fields,
        'tipo': 'JURIDICA'
      });
    }
  };

  function handleChange(evt) {
    const value = evt.target.value.replaceAll('_', '');

    setfields({
      ...fields,
      [evt.target.id]: value,
    });
  }


  // Submit
  const onSubmit = async data => {
    try {
      const {
        ativa,
        bloqueada,
        tipo,
        nome,
        apelido,
        cnpj,
        inscricaoEstadual,
        rg,
        cpf,
        enderecoNumero,
        enderecoComplemento,
        enderecoPais,
        enderecoCep,
        observacoes,
        telefone,
        email,
        email_nfe
      } = fields;

      await axios.post(
        `${API.clientes}`,
        {
          ativa: true,
          bloqueada: false,
          tipo,
          nome: nomeApi,
          apelido: nomeFantasiaApi,
          cnpj,
          inscricaoEstadual,
          cpf,
          rg,
          enderecoLogradouro: logradouroApi,
          enderecoNumero: numeroApi,
          enderecoComplemento,
          enderecoBairro: bairroApi,
          enderecoCidade: cidade,
          enderecoEstado: estado,
          enderecoPais: pais,
          enderecoCep: cep,
          observacoes,
          telefone,
          fiscalProfile: taxchoose,
          email: emailApi,
          email_nfe,
          vendedorPadrao: {
            id: valueAutoId,
            nome: valueAutoNome,
          },
        },
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      toast.success('Conta criada com sucesso');
      setOpen(false);

    } catch (err) {
      if (err.response) {
        toast.error('Falha na criação: ' + err.response.data.message);
      } else {
        toast.error('Falha na criação');
      }
    }
  };

  // Cep
  const handleCep = async e => {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${e.target.value}/json`,
      );
      const {
        logradouro: logradouroApi,
        bairro: bairroApi,
        uf: ufApi,
        localidade: localidadeApi,
        complemento: complementoApi,
      } = response.data;


      var retorno = await loadcity(response.data.ibge, '', 'codigo_fiscal');

      if (retorno[0]) {
        setCidade(retorno[0] ? retorno[0].value : '');
        setEstado(retorno[0] ? retorno[0].state : '');
        setPais(retorno[0] ? retorno[0].country : '');
        setcityDefault(retorno[0].label);

        const perfil_fisc = taxdefault.filter(item => item.label.includes(retorno[0].state_name));
        setTaxProfile(perfil_fisc)
        const selectedOption = perfil_fisc.find(item => item.label.includes('Regime Normal'));
        if (selectedOption) {
          setTaxChoose(selectedOption.value)
          setTaxChooseComplete(selectedOption)


        }

      }

      setLogradouro(logradouroApi);
      setBairro(bairroApi);
      setComplemento(complementoApi);
    } catch (err) {
      toast.error('Cep inválido');
    }
  };

  const handleCnpj = async e => {
    try {
      const response = await axios.get(`${API.clienteErpInfo}?cnpj=${e.target.value}`, {
        headers: {
          'x-access-token': token,
        },
      });
      const cepValue = response.data.data?.zipcode.replaceAll('.', '').replaceAll('-', '');

      setCep(cepValue);
      setNumero(response.data.data?.number)
      setTelefone(response.data.data?.phone)
      setEmail(response.data.data?.email)
      setNome(response.data.data?.name)
      setNomeFantasia(response.data.data?.fantasyName)

      handleCep({ target: { value: cepValue } });
    } catch (err) {
      //toast.error('Erro ao obter ');
    }
  }




  // Selects
  return (
    <div>
      <button className={classes.button} type="button" onClick={handleOpen}>
        <AddCircleOutlineIcon />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <input type="hidden" ref={register} name="id" />

                <Grid item xs={12} sm={12} lg={12}>
                  <center>
                    <h5>CLIENTE</h5>
                  </center>
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    select
                    label="Tipo"
                    name="tipo"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required="required"
                    onChange={e => handleRequiretipo(e.target.value)}
                  >
                    <MenuItem value={"JURIDICA"}>JURIDICO</MenuItem>
                    <MenuItem value={'FISICA'}>FÍSICA</MenuItem>

                  </TextField>

                </Grid>

                {tipoRequired ? (
                  <>
                    <Grid item xs={12} sm={12} lg={4}>
                      <InputMask
                        mask="99.999.999/9999-99"
                        onChange={handleChange}
                        onBlur={handleCnpj}
                      >
                        <TextField
                          id="cnpj"
                          label="Cnpj"
                          name="cnpj"
                          type="text"
                          style={{ width: '100%' }}
                          required={required ? required : 'required'}

                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <TextField
                        id="inscricaoEstadual"
                        label="Inscrição Estadual"
                        name="inscricaoEstadual"
                        type="text"
                        onChange={handleChange}
                        style={{ width: '100%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12} lg={4}>
                      <InputMask mask="999.999.999-99" onChange={handleChange}>
                        <TextField
                          label="CPF"
                          name="cpf"
                          id="cpf"
                          type="text"
                          required="required"
                          style={{ width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </InputMask>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={4}>
                      <TextField
                        label="RG"
                        name="rg"
                        id="rg"
                        type="text"
                        onChange={handleChange}
                        required="required"
                        style={{ width: '100%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <TextField
                        label="Data nascimento"
                        type="date"
                        defaultValue=""
                        name="datanasc"
                        ref={register}
                        InputLabelProps={{
                          shrink: true,
                        }}

                      />

                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    id="nome"
                    label="Nome"
                    type="text"
                    style={{ width: '100%' }}

                    onChange={e => {
                      setNome(e.target.value);
                    }}

                    value={nomeApi}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    id="apelido"
                    label="Apelido"
                    type="text"
                    value={nomeFantasiaApi}

                    onChange={e => {
                      setNomeFantasia(e.target.value);
                    }}
                    style={{ width: '100%' }}
                    required="required"
                    ref={register}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>


                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    id="enderecoCep"
                    label="CEP"
                    name="enderecoCep"
                    type="text"
                    required="required"
                    value={cep}

                    onChange={e => {
                      setCep(e.target.value);
                    }}
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={handleCep}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Endereço"
                    id="enderecoLogradouro"
                    name="enderecoLogradouro"
                    type="text"

                    required="required"
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={logradouroApi}
                    onChange={e => setLogradouro(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Número"
                    id="enderecoNumero"
                    name="enderecoNumero"
                    required="required"
                    type="text"
                    value={numeroApi}

                    onChange={e => {
                      handleChange(e);
                      setNumero(e.target.value);
                    }}

                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Complemento"
                    id="enderecoComplemento"
                    name="enderecoComplemento"
                    type="text"
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Bairro"
                    id="enderecoBairro"
                    name="enderecoBairro"
                    type="text"

                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    ref={register}
                    required="required"
                    value={bairroApi ? bairroApi : ''}
                    onChange={e => setBairro(e.target.value)}
                  />
                </Grid>


                <Grid item xs={12} sm={12} lg={4}>
                  <label>Cidade</label>


                  <Async
                    //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                    loadOptions={
                      debouncedLoadOptioncity
                    }
                    cacheOptions
                    styles={{

                      menuPortal: base => ({
                        ...base,
                        zIndex: 1600,
                      }),
                      container: provided => ({
                        ...provided,
                        maxHeight: '100%',
                        height: '100%',
                        // ajuste a altura conforme necessário
                      }),
                      control: provided => ({
                        ...provided,
                        fontSize: '12px', // Ajusta o tamanho da fonte
                        height: '10px',
                        maxHeight: '1rem',  // ajuste a altura conforme necessário
                      }),
                      indicatorsContainer: base => ({
                        ...base,
                        display: 'flex',
                        alignItems: 'center',
                        height: '40px', // Ajusta a altura dos botões
                      }),
                      indicatorSeparator: base => ({
                        ...base,
                        display: 'none', // Remove o separador entre os botões
                      }),
                    }}
                    ref={register}
                    menuPlacement={'top'}   // Define a posição do menu para cima
                    isClearable={true}

                    noOptionsMessage={() =>
                      'Nenhuma opção encontrada'
                    }

                    menuPortalTarget={document.body}

                    value={{ value: cidade, label: cityDefault }}
                    onChange={valor => {

                      setCidade(valor !== null ? valor.value : '');
                      setcityDefault(valor !== null ? valor.label : '')
                      setEstado(valor !== null ? valor.state : '');
                      setPais(valor !== null ? valor.country : '');

                    }}
                    onSelect={valor => {

                      setCidade(valor !== null ? valor.value : '');
                      setEstado(valor !== null ? valor.state : '');
                      setPais(valor !== null ? valor.country : '');

                    }}

                  />

                </Grid>


                <Grid item xs={12} sm={12} lg={4}>
                  <label>Vendedor</label>
                  <Select
                    options={auto}
                    loadOptions={auto}
                    isClearable={
                      perfil !== 'vendedor' ? true : false
                    }
                    value={{ label: valueAutoNome, value: valueAutoId }}

                    onChange={value => {
                      setValueAutoId(value !== null ? value.value : '');
                      setValueAutoNome(value !== null ? value.label : '');
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <label>Perfil Fiscal</label>
                  <Select
                    options={taxProfile}
                    loadOptions={taxProfile}
                    isClearable={true}
                    value={taxchoosecomplete}
                    onChange={value => {
                      setTaxChooseComplete(value)
                      setTaxChoose(value !== null ? value.value : '');
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <TextField
                    label="Observações"
                    id="observacoes"
                    name="observacoes"
                    multiline
                    rows={5}
                    type="text"
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <center><h5>Contatos </h5></center>
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Email"
                    id="email"
                    name="email"
                    required="required"
                    type="text"
                    value={emailApi}

                    onChange={e => {
                      setEmail(e.target.value);
                    }}

                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <InputMask mask="(99)999999999"
                    maskPlaceholder=""
                    onChange={handleChange}>
                    <TextField
                      label="Telefone"
                      id="telefone"
                      name="telefone"
                      type="text"
                      required="required"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </InputMask>

                </Grid>





                <Grid item xs={12} sm={12} lg={8}>

                  <span>* Campos obrigatórios</span>
                </Grid>
              </Grid>
              <ButtonStyled variant="contained" color="primary">
                Criar
              </ButtonStyled>
            </Form>
          </div>
        </Fade>
      </Modal>
    </div >
  );
}
