const server =   `https://textura-api.salesbreath.com.br/api/v1`;

//const server =   `http://localhost:3010/api/v1`;

export const API = {
    login: `${server}/usuarios/login`,
    clientes: `${server}/clientes`,
    clienteEndereco: `${server}/clientes/endereco`,
    cidadeErp:`${server}/utilities/city`,
    clientesPerfilFiscal:`${server}/clientes/perfilfiscal`,
    clientesByname:`${server}/clientes/contasByName`,
    vendedores: `${server}/clientes/vendedores`,
    totalfaturado: `${server}/dashboard/totalfaturado`,
    qtdvendasmes: `${server}/dashboard/qtdvendasmes`,
    vlvendasmes:`${server}/dashboard/vlvendasmes`,
    empresa: `${server}/empresa`,
    organizacao:`${server}/organizacao`,
    pedidos:`${server}/pedidos`,
    listaprecos:`${server}/regras/lista_precos`,
    transportadorasByClienteId:`${server}/clientes/transportadorasByClienteId`,
    carrinho:`${server}/carrinho`,
    carrinhoremoveitem:`${server}/carrinho/removeItem`,
    carrinho_removecart:`${server}/carrinho/removecart`,
    adicionaaocarrinho:`${server}/carrinho/AdicionaAoCarrinho`,
    numeropedido_erp:`${server}/bancoERP/numeropedido`,
    sendmailPedidos:`${server}/utilities/sendmailPedidos`,
    transportadoras:`${server}/clientes/transportadoras`,
    produtos:`${server}/Produtos`,
    regras:`${server}/regras`,
    regrasatribuidas:`${server}/regras/regras_atribuidas`,
    usuarios:`${server}/usuarios`,
    usuarios_delete:`${server}/usuarios/delete`,
    empresa_erp:`${server}/empresa/erp`,
    impressos_pedidos:`${server}/impressos/pedidos`,
    produtos_especbyurl:`${server}/produtos/especByUrl`,
    produtos_espec:`${server}/impressos/espec`,
    refreshToken:`${server}/usuarios/refreshToken`,
    consultasaldo:`${server}/produtos/consultasaldo`,
    carteiras:`${server}/utilities/carteiras`,
    estados:`${server}/utilities/estados`,
    classes:`${server}/utilities/classes`,
    relatorios:`${server}/relatorios`,
    departamentos:`${server}/utilities/departamentos`,
    marcas:`${server}/utilities/marcas`,
    imagens_produtos:`${server}/utilities/imagens`,
    external_imagens:`${server}/utilities/externalimagens`,
    prazomedio:`${server}/utilities/prazomedio`,
    infocomerciais:`${server}/clientes/infocomerciais`,
    galeria:`https://textura-api.salesbreath.com.br/api/v1`,
    imagens_colecao:`http://textura.ddns.net:8181/Design`,
    statuspedidos:`${server}/utilities/statuspedidos`,
    usuarios_erp:`${server}/usuarios/usuarios_erp`,
    cliente_files: `${server}/clientes/arquivos`,
    cliente_files_delete: `${server}/clientes/arquivos`,
    getprzpag:`${server}/utilities/prazopagamento`,
    clienteSQL: `${server}/clientes/clienteSQL`,
    classesReport:`${server}/utilities/reportClasses`,
    produtos_NameOrCod:`${server}/Produtos/byNameAndCod`,
    qualidadeProdutos:`${server}/utilities/qualidadeProdutos`,
    priceListERP:`${server}/utilities/pricelistERP`,
    salvafiltro:`${server}/utilities/salvafiltro`,
    obtemfiltro:`${server}/utilities/obtemfiltro`,
    perfilVendas:`${server}/utilities/perfilvendas`,
    perfilFiscal:`${server}/utilities/perfilfiscal`,
    romaneio:`${server}/impressos/romaneio`,
    obtemtoken:`${server}/utilities/obtemtoken_erp`,
    businessGroup:`${server}/utilities/businessGroup`,
    operatorNotes:`${server}/pedidos/OperatorNotes`,
    clienteErpInfo: `${server}/clientes/erpclientinfo`,
    getMetas: `${server}/dashboard/getmetas`,
    getCurrency:`${server}/utilities/currency`,
    getCurrencyById:`${server}/utilities/currencyById`,

    



}  
