import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Pesquisa } from './styles';
import { Form, ButtonStyled } from './styles';
import Autocomplete from 'react-autocomplete';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { toast } from 'react-toastify';

import ModalClientes from '../../components/Clientes/ModalClientes';
import DataTable from 'components/Table/Table.js';
import { API } from '../../config/api';
import { TablePagination, Divider } from '@material-ui/core';
import ModalCreate from "../../components/Clientes/ModalCreate";


const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: '100vh',
    paddingTop: '2px',
  },
}));

function createRow(
  edit,
  id,
  name,
  fantasyName,
  cnpj,
  addressStreet,
  addressDistrict,
  city,
) {
  return {
    edit,
    id,
    name,
    fantasyName,
    cnpj,
    addressStreet,
    addressDistrict,
    city,
  };
}

const tableColumns = [
  { field: 'edit' },
  {
    title: 'Código',
    field: 'id',
    defaultSort: 'desc',
    headerStyle: {
      width: 10,
      maxWidth: 10,
      color: '#fff',
    },
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Nome',
    field: 'name',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Nome fantasia',
    field: 'fantasyName',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'CNPJ/CPF',
    field: 'cnpj',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
  },
  {
    title: 'Endereço',
    field: 'addressStreet',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Bairro',
    field: 'addressDistrict',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Cidade',
    field: 'city',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
];

export default function Clients() {
  const classes = useStyles();
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const profile = sessionStorage.getItem('profile');
  const perfil = sessionStorage.getItem('perfil');
  const { register, getValues } = useForm();
  const [clientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedAutoId, setSelectedAutoId] = useState('');
  const [selectedAutoName, setSelectedAutoName] = useState('');

  useEffect(() => {
    async function cleanAutoValue() {
      setSelectedAutoId('');
    }
    if (selectedAutoName.length === 0) {
      cleanAutoValue();
    }
  }, [selectedAutoName]);

  useEffect(() => {
    async function fetchAutocompleteData() {
      try {
        const response = await axios.get(`${API.vendedores}?email=${email}`, {
          headers: {
            'x-access-token': token,
          },
        });

        const list = response.data.data;
        setAutocompleteData(list);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    fetchAutocompleteData();
  }, []);

  let value = '';
  let valueId = '';
  if (profile === 'vendedor') {
    // value = !clientes ? '' : clientes[0].vendedorPadrao.nome;
    // valueId = !clientes ? '' : clientes[0].vendedorPadrao.id;
  }
  const [valueAutoId, setValueAutoId] = useState(valueId);
  const [valueAutoName, setValueAutoName] = useState(value);

  const searchItem = (data, e) => {
    e.preventDefault();
    setLoading(true);
    handleSearch(data);
    setPanelOpen(false);
  };

  const handleSearch = async data => {
    try {
      setClientList('');

      var axiosSearch = '';


      if (data.codigo > 0) {
        console.log(data.codigo);
        axiosSearch = `${API.clientes}/${data.codigo}`;
      } else {
        if (
          data.vendedorPadraoId > 0 ||
          data.nome !== '' ||
          data.cnpj != '' ||
          data.cidade !== ''
        ) {
          var where = '';
          var nome = encodeURIComponent(data.nome);
          var cidade = encodeURIComponent('city.name');

          where = data.vendedorPadraoId
            ? where + `&properties.salesPerson=${data.vendedorPadraoId}`
            : where;

          where = data.nome ? where + `&fantasyName='*${nome}*'` : where;

          where = data.cnpj ? where + `&documentNumber=*${data.cnpj}*` : where;
          where = data.cidade ? where + `&${cidade}='*${data.cidade}*'` : where;

          axiosSearch = `${API.clientes}?email=${email}${where}`;
          //      console.log(axiosSearch)
        } else {
          //   var where = `&cliente=true&ativa=true`;
          // axiosSearch = `${API.clientes}?email=${email}${where}`;
        }
      }


      const response = await axios.get(`${axiosSearch}`, {
        headers: {
          'x-access-token': token,
        },
      });
      //console.log(response.data.data);
      const itemData = response.data.data.length > 0 ? response.data.data.filter(item => item.hasOwnProperty('name')) : [];
      var k = '';
      var v = ';';
      for (const obj of itemData) {
        if (typeof obj !== 'object') continue;
        for (k in obj) {
          if (!obj.hasOwnProperty(k)) continue;
          v = obj[k];
          if (v === null || v === undefined) {
            obj[k] = '';
          }
        }
      }
      //  console.log(item);

      setClientList(itemData);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error.response && error.response.status === 402) {
        // Expired token
        toast.error('Token expired. Please log in again.');
        sessionStorage.clear();
      } else if (error.response && error.response.status === 404) {
        // No clients found
        toast.error('No clients found with these parameters.');
        setClientList([]);
      } else {
        toast.error('Error while searching. Please check the parameters.');
      }
    }
  }

  const teste = clientList ? clientList.map(item => item.name.length == 0 ? console.log(item.name + item.id) : '') : '';

  const rowsList = clientList
    ? clientList.map(item => {

      const row = createRow(
        <ModalClientes data={item} />,
        item.id ? item.id : '',
        item.name ? item.name : '',
        item.fantasyName ? item.fantasyName : '',
        item.documentNumber ? item.documentNumber : '',
        item.street ? item.street : '',
        item.district ? item.district : '',
        item.city && item.city.name ? item.city.name : '',
      );

      return row;

    })
    : [{ error: 'Not found' }];

  return (
    <>
      <Paper className={classes.paper}>
        <Pesquisa>
          <div>
            <ExpansionPanel expanded={isPanelOpen}>
              <ExpansionPanelSummary
                expanded={isPanelOpen}
                onClick={() => {
                  setPanelOpen(!isPanelOpen);
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography component={'span'} className={classes.heading}>
                  Painel de pesquisa
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails expanded={true}>
                <Typography component={'div'}>
                  <Form>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} lg={2}>
                        <div className="input">
                          <label>ID</label>
                          <input type="number" name="codigo" ref={register} />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={6}>
                        <div className="input">
                          <label>Nome</label>
                          <input type="text" name="nome" ref={register} />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <div className="input">
                          <label>Cnpj</label>
                          <input type="text" name="cnpj" ref={register} />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <div className="input">
                          <label>Cidade</label>
                          <input type="text" name="cidade" ref={register} />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={8}>
                        <div className="input">
                          <label>Vendedor</label>
                          <input
                            name="vendedorPadraoId"
                            type="hidden"
                            ref={register}
                            defaultValue={valueAutoId}
                          />
                          <input
                            name="vendedorPadraoNome"
                            type="hidden"
                            ref={register}
                          />
                          <Autocomplete
                            items={autocompleteData}
                            wrapperStyle={{
                              position: 'relative',
                              zIndex: '15',
                              display: 'inline-block',
                            }}
                            shouldItemRender={(item, value) =>
                              item.name
                                .toLowerCase()
                                .indexOf(value.toLowerCase()) > -1
                            }
                            getItemValue={item => {
                              setValueAutoId(item.id);
                              return item.name;
                            }}
                            renderItem={(item, isHighlighted) => (
                              <div
                                key={item.id}
                                inputVariant="outlined"
                                style={{
                                  background: isHighlighted
                                    ? 'lightgray'
                                    : 'white',
                                  width: '100%',
                                }}
                              >
                                <span
                                  key={item.id}
                                  style={{
                                    fontWeight: isHighlighted ? 700 : 400,
                                  }}
                                >
                                  {item.name}
                                </span>
                              </div>
                            )}
                            value={valueAutoName}
                            onChange={e => setValueAutoName(e.target.value)}
                            onSelect={val => setValueAutoName(val)}
                          />
                        </div>
                      </Grid>                    </Grid>
                    <ButtonStyled
                      variant="contained"
                      color="primary"
                      onClick={e => searchItem(getValues(), e)}
                    >
                      Pesquisar
                    </ButtonStyled>
                  </Form>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Pesquisa>
          <ModalCreate />
        <DataTable
          load={loading}
          rows={rowsList}
          rowHead={tableColumns}
          sort={true}
          title={'Clientes'}
        />
      </Paper>
    </>
  );
}
