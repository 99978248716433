import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;

  input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    position: relative;
    width: auto;
  }
`;

export const Input = styled.input`
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  position: relative;
  width: 80%;
`;

export const Select = styled.select`
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  position: relative;
  width: 80%;
`;

export const ButtonStyled = styled.button`
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px;
  border: 0 none;
  border-radius: 5px;
  background-color: #00acc1;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
`;

export const ButtonRequest = styled.button`
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px;
  border: 0 none;
  border-radius: 5px;
  background-color: #00acc1;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;