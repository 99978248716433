import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Badge, CircularProgress, Tooltip } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { Button, makeStyles } from "@material-ui/core";

import { API } from '../../../config/api';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { PhotoCamera } from '@material-ui/icons';

import {
  EmailIcon,
  WhatsappIcon,
  EmailShareButton,
  WhatsappShareButton,
} from 'react-share';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 0, 0),
    maxWidth: '550px',
    maxHeight: '650px',
    overflow: 'auto',
  },

  button: {
    position: "relative",
    minWidth: "30px",
    padding: theme.spacing(1),
  },
  badge: {
    backgroundColor: '#4caf50',
    fontWeight: "bold",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 4px",
    borderRadius: "4px",
  },
  spinner: {
    color: '#4caf50',
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

}));

export default function ImagesProducts({ num }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imagens, setImagens] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePdf = async () => {
    setLoading(true);

    try {
      const req = await axios.get(
        `${API.external_imagens}?item_id=${num}&type=carrosel`,
        {
          headers: {
            'x-access-token': sessionStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );

      const new_img = req.data.data.map(item => ({
        url: item.url,
        nome: item.name,
        urlext: item.url,
      }));

      setImagens(new_img);
      setOpen(true);
    } catch (err) {
      toast.error('Nenhuma imagem encontrada.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Imagens">
        <span>
          <Button
            className={classes.button}
            onClick={handlePdf}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} className={classes.spinner} />
            ) : (
              <PhotoCamera />
            )}
          </Button>
        </span>
      </Tooltip>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 1000 }}
      >
        <div className={classes.paper}>
          <Carousel>
            {imagens.map(image => (
              <div>
                <div className="img">
                  <WhatsappShareButton url={image.urlext} className="m-2">
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <EmailShareButton url={image.urlext} className="m-2">
                    <EmailIcon size={32} round />
                  </EmailShareButton>

                  <div className="Demo__some-network__share-count">&nbsp;</div>
                </div>
                <img src={image.url} />
                <p className="legend">{image.nome}</p>
              </div>
            ))}
          </Carousel>
        </div>
      </Modal>
    </>
  );
}

ImagesProducts.propTypes = {
  num: PropTypes.number.isRequired,
};
