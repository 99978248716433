import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';

import history from '../../../services/history';
import api from '../../../services/api';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import { css } from 'glamor';
import { API } from '../../../config/api';

import { signInSuccess, signInFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(api.post, 'usuarios/login', {
      email,
      senha: password,
    });

    let { token, perfil, id, id_erp, ordenacao_lista, frete } = response.data.data;
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('perfil', perfil);
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('id', id);
    sessionStorage.setItem('id_erp', id_erp);
    sessionStorage.setItem('ordenacao_lista', ordenacao_lista);
    sessionStorage.setItem('frete', frete);
    sessionStorage.setItem('signed', 'true');

    async function ObtemFiltros(id) {
      try {
        const response = await axios.get(
          `${API.obtemfiltro}/${id}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        const filtros = response.data.data;

        // Agrupar os filtros por referência
        const filtrosAgrupados = filtros.reduce((agrupamento, filtro) => {
          const { referencia } = filtro;

          if (!agrupamento[referencia]) {
            agrupamento[referencia] = [];
          }

          agrupamento[referencia].push(filtro);

          return agrupamento;
        }, {});

        // Armazenar no localStorage, usando o nome da referência como chave
        Object.keys(filtrosAgrupados).forEach((referencia) => {
          const valorLocalStorage = JSON.stringify(filtrosAgrupados[referencia]);

          sessionStorage.setItem(referencia, valorLocalStorage);
        });

        // Retorna filtros para uso posterior se necessário
        return filtros;
      } catch (err) {
        toast.error('Não encontrado');
        return [];
      }
    }

    // Chama ObtemFiltros e espera até que seja concluído
    const filtros = yield call(ObtemFiltros, id);

    // Agora você pode manipular os filtros se necessário

    // Reload da página após todas as operações assíncronas
    window.location.reload();

  } catch (err) {
    toast.error('Falha na autenticação, verifique seus dados');

    yield put(signInFailure());
  }
}
export function signOut() {
  var answer = window.confirm('Deseja sair do sistema?');
  if (answer) {
    sessionStorage.clear();
    localStorage.clear();
    history.push('/');
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
