import styled, { css } from 'styled-components';
import bg from "../../assets/img/layout.png";



export const Container = styled.div`
  padding: 20px;
  max-height: 100%;
  background: url(${bg});
  ${(props) =>
    props.bg &&
    css`
      background: #fff;
    `}
  color: var(--text);

  h6 {
    font-size: 17px !important;
  }
  font-size: 14px;
`;
export const ButtonEdit = styled.button`
  border: 0;
  background: transparent;
  color: var(--blue);
  text-align: center;
`;

export const BgNone = styled.section`
  background-color: #fff;
`;

export const Button = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #28a745;
`;

export const Header = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  h1 {
    font-size: 29px;
    text-align: center;
    color: var(--blue-b);
    flex: 1;
  }

  button {
    border: 0;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #28a745;
    position: absolute;
    right: 0;
  }
`;

export const ButtonIcon = styled.button`
  border: 0;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c53030;

  svg {
    color: #fff;
  }
`;
export const Pesquisa = styled.div`
  margin-bottom: 20px;
`;


export const Form = styled.form`
  display: flex;
  flex-direction: column;

  .input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    label {
      font-weight: bold;
    }
  }

  input,
  select {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    position: relative;
    width: 100%;
  }
`;

export const ButtonStyled = styled.button`
  margin-top: 5px;
  margin-left: 0px;
  width: auto;
  align:right;
  
  padding: 10px;
  border: 0 none;
  background-color: #00acc1;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  position:relative;
  cursor: pointer;
  &:hover {
    border-radius: 20px;
    opacity: 0.7;

  }
`;